import React from 'react'
import LayoutContainer from '../container'
import styled from '@emotion/styled'
import AlertIcon from '../../images/alert-circle-fill.svg'

const NotificationContainer = styled.div`
    background-color: var(--color-blue-alpha);
    backdrop-ilter: blur(10px);
    padding: 0.85rem 1rem 0.7rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    line-height: 1;
`

const Icon = styled.img`
    margin-bottom: 0;
    margin-right: 1rem;
`

const TextContainer = styled.div`
    color: #fff;
    display: flex;
    align-items: flex-start;

    flex: 1;
    flex-direction: column;

    @media (min-width: 769px) {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
`

export default function NotificationBar({ children }) {
    return (
        <LayoutContainer>
            <NotificationContainer>
                <Icon src={AlertIcon} />
                <TextContainer>{children}</TextContainer>
            </NotificationContainer>
        </LayoutContainer>
    )
}
