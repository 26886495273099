/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { useCookies } from 'react-cookie'

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import './layout.css'
import Delayed from '../components/Delayed'
import PopUp from '../components/PopUp/PopUp'

const BaseLayout = ({ children, fixedHeader, transparentHeader }) => {
    const [cookies, setCookie] = useCookies(['hasSeenPopup'])
    const hasSeenPopup = cookies.hasSeenPopup
    const setSeenCookie = () =>
        setCookie('hasSeenPopup', true, {
            path: '/',
        })
    const data = useStaticQuery(graphql`
        query BaseLayoutQuery {
            site {
                siteMetadata {
                    title
                }
            }
            allWp {
                nodes {
                    popupSettings {
                        popupSettings {
                            enablePopup
                            popupFields {
                                button {
                                    target
                                    title
                                    url
                                }
                                fieldGroupName
                                image {
                                    localFile {
                                        childImageSharp {
                                            fixed(height: 300, width: 400) {
                                                ...GatsbyImageSharpFixed
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    const popupSettings = data.allWp.nodes[0].popupSettings.popupSettings
    return (
        <>
            <Header
                siteTitle={data.site.siteMetadata.title}
                fixed={fixedHeader}
                transparent={transparentHeader}
            />
            {children}
            <Footer siteTitle={data.site.siteMetadata.title} />
            <Delayed waitBeforeShow={500}>
                {!hasSeenPopup && popupSettings.enablePopup && (
                    <PopUp data={popupSettings} setSeenCookie={setSeenCookie} />
                )}
            </Delayed>
        </>
    )
}

BaseLayout.propTypes = {
    children: PropTypes.node.isRequired,
    fixedHeader: PropTypes.bool,
    transparentHeader: PropTypes.bool,
}

export default BaseLayout
