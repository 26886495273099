import React from 'react'
import styled from '@emotion/styled'

const InlineList = styled.ul`
    list-style: none;
    margin: none;
    padding: none;
    display: flex;
    margin-left: 0;
    li {
        margin-right: 1rem;
        height: 1.3rem;
        width: 1.3rem;
    }
`

const TwitterLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.013 23.957">
        <path
            d="M18.84 8.773a6.07 6.07 0 001.574-1.687 6.854 6.854 0 01-1.743.506 3.5 3.5 0 001.35-1.8 6.865 6.865 0 01-2.081.788 2.673 2.673 0 00-1.74-.93 3.6 3.6 0 00-2 .281 3.259 3.259 0 00-1.49 1.406 2.874 2.874 0 00-.281 2.165 9.634 9.634 0 01-6.635-3.316 3.149 3.149 0 00-.253 2.446 2.918 2.918 0 001.207 1.828 4.15 4.15 0 01-1.35-.393 3.218 3.218 0 002.475 3.206 3.28 3.28 0 01-1.35.056A3.182 3.182 0 009.5 15.578a5.488 5.488 0 01-2.193 1.1 6.255 6.255 0 01-2.531.2A8.94 8.94 0 009.95 18.34a8.891 8.891 0 006.387-2.79 9.726 9.726 0 002.503-6.777zM21.6 0a2.334 2.334 0 011.715.7 2.266 2.266 0 01.7 1.659v19.236a2.267 2.267 0 01-.7 1.659 2.337 2.337 0 01-1.715.7H2.418A2.334 2.334 0 01.7 23.25a2.266 2.266 0 01-.7-1.655V2.362A2.267 2.267 0 01.7.703 2.337 2.337 0 012.418 0z"
            fill="#d5e8fb"
        />
    </svg>
)

const LinkedInLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.013 23.957">
        <path
            d="M20.414 20.358V13.55A4.223 4.223 0 0016.2 9.335a3.4 3.4 0 00-1.687.478 3.507 3.507 0 00-1.294 1.209V9.56h-3.6v10.8h3.6v-6.357a1.778 1.778 0 01.506-1.265 1.67 1.67 0 011.265-.534 1.762 1.762 0 011.294.534 1.73 1.73 0 01.534 1.265v6.355zM5.4 7.536a2.16 2.16 0 001.547-.619 2.017 2.017 0 00.647-1.518 2.115 2.115 0 00-.648-1.549A2.106 2.106 0 005.4 3.206a2.113 2.113 0 00-1.548.644 2.113 2.113 0 00-.647 1.547 2.014 2.014 0 00.647 1.518 2.167 2.167 0 001.548.621zm1.8 12.822V9.561H3.6v10.8h3.6zM21.6 0a2.334 2.334 0 011.715.7 2.266 2.266 0 01.7 1.659v19.236a2.267 2.267 0 01-.7 1.659 2.337 2.337 0 01-1.715.7H2.418A2.334 2.334 0 01.7 23.25a2.266 2.266 0 01-.7-1.655V2.362A2.267 2.267 0 01.7.703 2.337 2.337 0 012.418 0z"
            fill="#d5e8fb"
        />
    </svg>
)

export default function SocialIcons() {
    return (
        <InlineList>
            <li>
                <a href="https://twitter.com/bluespec">
                    <TwitterLogo />
                </a>
            </li>
            <li>
                <a href="https://www.linkedin.com/company/bluespec-inc">
                    <LinkedInLogo />
                </a>
            </li>
        </InlineList>
    )
}
