/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import BaseLayout from './base'

const Layout = ({ children, fixedHeader, transparentHeader }) => (
    <BaseLayout fixedHeader={fixedHeader} transparentHeader={transparentHeader}>
        <div id="content">
            <main>{children}</main>
        </div>
    </BaseLayout>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    fixedHeader: PropTypes.bool,
    transparentHeader: PropTypes.bool,
}

export default Layout
