import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import classNames from 'classnames/bind'
import Link from '../Link/Link'
import * as styles from './Header.module.css'
import Logo from '../Logo/Logo'
import NotificationBar from '../NotificationBar/NotificationBar'
import Button from '../Button/Button'

const NotificationCTAButton = styled(Button)`
    padding-left: 0;
    white-space: nowrap;
    @media (min-width: 769px) {
        padding-left: unset;
        margin-left: 1rem;
    }
`
class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = { menuOpen: false, scrolled: false }
        this.handleScroll = this.handleScroll.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll() {
        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop
        this.setState({ scrolled: scrollTop >= 50 })
    }

    openMenu() {
        this.setState({ menuOpen: !this.state.menuOpen })
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        site {
                            siteMetadata {
                                title
                                menuLinks {
                                    name
                                    link
                                }
                            }
                        }
                        allWp {
                            nodes {
                                popupSettings {
                                    notificationBarSettings {
                                        enableNotificationBar
                                        notificationBarFields {
                                            fieldGroupName
                                            text
                                            button {
                                                target
                                                title
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
                render={data => {
                    const popupSettings =
                        data.allWp.nodes[0].popupSettings
                            .notificationBarSettings

                    const enableNotificationBar =
                        popupSettings.enableNotificationBar
                    const navItems = data.site.siteMetadata.menuLinks
                    const title = data.site.siteMetadata.title
                    const isFixed = !!this.props.fixed
                    const cx = classNames.bind(styles)
                    const headerClassNames = cx('wrapper', {
                        fixed: isFixed,
                        transparent: this.props.transparent,
                        scrolled: this.state.scrolled && isFixed,
                    })
                    return (
                        <header className={headerClassNames}>
                            {enableNotificationBar && (
                                <NotificationBar>
                                    <span>
                                        {
                                            popupSettings.notificationBarFields
                                                .text
                                        }
                                    </span>
                                    <NotificationCTAButton
                                        to={
                                            popupSettings.notificationBarFields
                                                .button.url
                                        }
                                        type="link"
                                    >
                                        {
                                            popupSettings.notificationBarFields
                                                .button.title
                                        }
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 256 256"
                                            height="0.6rem"
                                            fill="#fff"
                                            style={{
                                                marginLeft: '0.25em',
                                            }}
                                        >
                                            <path d="M79.093 0L48.907 30.187 146.72 128l-97.813 97.813L79.093 256l128-128z" />
                                        </svg>
                                    </NotificationCTAButton>
                                </NotificationBar>
                            )}
                            <div className={styles.header}>
                                <h1 className={styles.logo}>
                                    <Link to="/" title={title}>
                                        <Logo fill="#fff" />
                                    </Link>
                                </h1>
                                <nav
                                    className={[
                                        styles.nav,
                                        this.state.menuOpen
                                            ? styles.menuOpen
                                            : '',
                                    ].join(' ')}
                                >
                                    <button
                                        className={[
                                            styles.mobileToggle,
                                            styles.mobileClose,
                                        ].join(' ')}
                                        onClick={this.openMenu.bind(this)}
                                    >
                                        <svg
                                            version="1.1"
                                            id="Capa_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 212.982 212.982"
                                            style={{
                                                enableBackground:
                                                    'new 0 0 212.982 212.982',
                                            }}
                                            xmlSpace="preserve"
                                        >
                                            <path
                                                style={{
                                                    fillRule: 'evenodd',
                                                    clipRule: 'evenodd',
                                                }}
                                                d="M131.804,106.491l75.936-75.936c6.99-6.99,6.99-18.323,0-25.312
                        c-6.99-6.99-18.322-6.99-25.312,0l-75.937,75.937L30.554,5.242c-6.99-6.99-18.322-6.99-25.312,0c-6.989,6.99-6.989,18.323,0,25.312
                        l75.937,75.936L5.242,182.427c-6.989,6.99-6.989,18.323,0,25.312c6.99,6.99,18.322,6.99,25.312,0l75.937-75.937l75.937,75.937
                        c6.989,6.99,18.322,6.99,25.312,0c6.99-6.99,6.99-18.322,0-25.312L131.804,106.491z"
                                            />
                                        </svg>
                                    </button>
                                    <ul className={styles.menu}>
                                        {navItems.map((navItem, i) => (
                                            <li key={i}>
                                                <Link to={navItem.link}>
                                                    {navItem.name}
                                                </Link>
                                            </li>
                                        ))}
                                        <li>
                                            <Link
                                                to="/contact"
                                                className={styles.cta}
                                            >
                                                Contact
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                                <button
                                    className={styles.mobileToggle}
                                    onClick={this.openMenu.bind(this)}
                                >
                                    <svg
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 512 512"
                                        xmlSpace="preserve"
                                        style={{
                                            enableBackground: 'new 0 0 512 512',
                                        }}
                                        fill="#fff"
                                    >
                                        <g>
                                            <g>
                                                <path
                                                    d="M491.318,235.318H20.682C9.26,235.318,0,244.577,0,256s9.26,20.682,20.682,20.682h470.636
                        c11.423,0,20.682-9.259,20.682-20.682C512,244.578,502.741,235.318,491.318,235.318z"
                                                />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path
                                                    d="M491.318,78.439H20.682C9.26,78.439,0,87.699,0,99.121c0,11.422,9.26,20.682,20.682,20.682h470.636
                        c11.423,0,20.682-9.26,20.682-20.682C512,87.699,502.741,78.439,491.318,78.439z"
                                                />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path
                                                    d="M491.318,392.197H20.682C9.26,392.197,0,401.456,0,412.879s9.26,20.682,20.682,20.682h470.636
                        c11.423,0,20.682-9.259,20.682-20.682S502.741,392.197,491.318,392.197z"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </header>
                    )
                }}
            />
        )
    }
}

export default Header

Header.propTypes = {
    fixed: PropTypes.bool,
    transparent: PropTypes.bool,
}
