import React from 'react'
import PropTypes from 'prop-types'
import Link from '../Link/Link'
import classNames from 'classnames/bind'
import * as styles from './Button.module.css'

const cx = classNames.bind(styles)

const Button = ({ type, sm, children, className, ...rest }) => {
    const classNames = cx(
        {
            btn: true,
            btnPrimary: !type || type === 'primary',
            btnSecondary: type === 'secondary',
            btnLink: type === 'link',
            btnSm: sm,
        },
        className
    )

    return (
        <Link className={classNames} {...rest}>
            {children}
        </Link>
    )
}

export default Button

Button.propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'link']),
    sm: PropTypes.bool,
    children: PropTypes.node,
}
