import React from 'react'

const Container = ({ children, className }) => (
    <div
        className={className}
        style={{
            maxWidth: '1200px',
            margin: '0 auto',
            padding: '1rem var(--site-padding-x)',
        }}
    >
        {children}
    </div>
)

export default Container
