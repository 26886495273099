import PropTypes from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import HubspotForm from 'react-hubspot-form'
import * as styles from './Footer.module.css'
import Logo from '../Logo/Logo'
import Link from '../Link/Link'
import SocialIcons from '../SocialIcons/SocialIcons'

const FooterColumns = styled.div`
    background-color: var(--color-licorice);
    padding: 1.75rem var(--site-padding-x);
    & > * {
        margin-bottom: 1rem;
    }
    @media (min-width: 769px) {
        display: flex;
    }
`

const ContactInfoColumn = styled.div`
    margin-right: 2rem;
    min-width: min-content;
`

const FooterRows = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
`

const Menu = styled.ul`
    list-style: none;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 3rem;

    li {
        margin-bottom: 0;
    }

    @media (min-width: 769px) {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        li {
            font-size: 1.25rem;
            margin-left: 4em;
        }
    }
`

const Copyright = styled.div`
    font-size: 0.9rem;
    background-color: var(--color-dark-licorice);
    color: #fff;
    text-align: center;
    padding: 1em;
`

const SubscribeFormContainer = styled.div`
    width: 100%;
    form {
        @media (min-width: 769px) {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }
    }
    form,
    ul,
    li {
        margin: 0;
    }

    ul[role='alert'] {
        display: none !important;
    }

    input {
        background: none;
        border: none;
        appearance: none;
    }

    .hs-fieldtype-text {
        position: relative;
        flex: 1;
        max-width: 500px;

        margin-bottom: 1rem;
        @media (min-width: 769px) {
            margin-bottom: 0;
            margin-right: 1rem;
        }
    }

    input[type='email'] {
        border-bottom: 1px solid var(--color-light-blue);
        margin-right: 1rem;
        padding-top: 0.4em;
        padding-bottom: 0.4em;
        color: #fff;
        width: 100%;
    }

    input[type='submit'] {
        border: 1px solid var(--color-light-blue);
        padding: 0.5rem;
        color: var(--color-light-blue);
        text-transform: uppercase;
        font-family: var(--font-heading);
        font-weight: 700;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.2s all ease;
        line-height: normal;

        &:hover {
            background-color: var(--color-light-blue);
            color: var(--color-licorice);
        }
    }
`

const Footer = ({ siteTitle }) => {
    return (
        <footer className={styles.footer}>
            <FooterColumns>
                <ContactInfoColumn>
                    <Logo width="200px" styles={{ display: 'block' }} />
                    <address>
                        1671 Worcester Road, Suite 101
                        <br />
                        Framingham, MA 01701
                        <br />
                        <a href="mailto:sales@bluespec.com">
                            sales@bluespec.com
                        </a>
                    </address>
                    <SocialIcons />
                </ContactInfoColumn>
                <FooterRows>
                    <Menu>
                        <li>
                            <Link to="/products">Products</Link>
                        </li>
                        <li>
                            <Link to="/company">Company</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </Menu>
                    <SubscribeFormContainer>
                        <HubspotForm
                            className="form"
                            portalId="3621575"
                            formId="43008337-0c5d-461a-8a39-8e915cdf5ff0"
                        />
                    </SubscribeFormContainer>
                </FooterRows>
            </FooterColumns>
            <Copyright>
                © {new Date().getFullYear()} <Link to="/">{siteTitle}</Link>.
                Website Design &amp; Development by{' '}
                <a href="https://stirlingbrandworks.com">Stirling Brandworks</a>
                .
            </Copyright>
        </footer>
    )
}

Footer.propTypes = {
    siteTitle: PropTypes.string,
}

Footer.defaultProps = {
    siteTitle: '',
}

export default Footer
