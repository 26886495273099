// extracted by mini-css-extract-plugin
export var wrapper = "Header-module--wrapper--7g-D7";
export var transparent = "Header-module--transparent--19AfI";
export var scrolled = "Header-module--scrolled--18WO-";
export var header = "Header-module--header--AWpe5";
export var menu = "Header-module--menu--2nuVG";
export var nav = "Header-module--nav--2Vqsn";
export var menuOpen = "Header-module--menuOpen--MW2e3";
export var mobileToggle = "Header-module--mobileToggle--33b43";
export var mobileClose = "Header-module--mobileClose--1XO-e";
export var logo = "Header-module--logo--2EZVS";
export var cta = "Header-module--cta--1PtL-";