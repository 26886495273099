import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

import Button from '../Button/Button'
import * as styles from './PopUp.module.css'

export default function Header({ data, setSeenCookie }) {
    return (
        <div className={styles.modal}>
            <div className={styles.modalContent}>
                <button className={styles.close} onClick={setSeenCookie}>
                    &times;
                </button>
                {data.popupFields.image && (
                    <Img
                        fixed={
                            data.popupFields.image.localFile.childImageSharp
                                .fixed
                        }
                    />
                )}
                <span className={styles.modalText}>
                    {data.popupFields.text}
                </span>
                <Button
                    to={data.popupFields.button.url}
                    onClick={setSeenCookie}
                >
                    {data.popupFields.button.title}
                </Button>
            </div>
        </div>
    )
}

Header.propTypes = {
    data: PropTypes.object.isRequired,
    setSeenCookie: PropTypes.func.isRequired,
}
